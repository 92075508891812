<template>
    <div class="code-input" v-if="ready">
        <div class="d-flex justify-center" :key="iKey">
            <template v-for="(v, index) in size">
                <v-text-field 
                    type="number"
                    pattern="\d*"
                    outlined
                    color="primary"
                    class="elevation-1"
                    maxlength="1"
                    :ref="iRefs[index]"
                    placeholder="#" 
                    hide-details
                    :autofocus="!$vuetify.breakpoint.smAndDown && index==0"
                    @input="(e) => handleInput(e, index)"
                    @keydown="(e) => handleKeyPress(e, index)"
                    v-model="values[index]"
                    :key="'cI-' + index"
                    max="9"
                />
            </template>
        </div>
    </div>
</template>
<script>

export default {

  name: 'CodeInput',

    props: {
        size: {
            required: false,
            type: Number,
            default: 4
        },
        defaultCode: {
            required: false,
            default: null
        }
    },

    computed: {
        
    },
    data: () => ({
        iKey: 0,
        iRefs: [],
        ready: false,
        values: []
    }),
    methods: {
        initData(){
            this.initRefs();
            if(this.defaultCode){
                // for preview only, displays user's last 4, etc.
                this.values = this.defaultCode.split("");
            }
        },
        initFields(){
            if(!this.$vuetify.breakpoint.smAndDown){
                let first = this.iRefs[0];
                this.$refs[first][0].focus();
            }
        },
        initRefs(){
            for (let i = 0; i < this.size; i++) {
                this.iRefs.push(`input_${i}`);
            }
            this.ready = true;
        },
        handleInput(e, index){
            if(e > 9){
                e = e[0];
            }
            this.values[index] = e;
            let next = (index < this.size - 1) ? index + 1 : index;
            next = this.iRefs[next];
            this.$refs[next][0].focus();
        },
        handleKeyPress(e, index){
            // console.log(e, index);
            let target;
            if(index == this.size -1 && this.values[index]){
                // prevents more than 1 number being entered
                e.preventDefault();
            }
            // Number entered
            // if(this.values[index] && ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) !== -1){
            //     console.log('here', parseInt(e.key));
            //     this.values[index] = parseInt(e.key);
            //     //target = this.iRefs[index];

            //     // this.$refs[target][0].focus();
            //     if(index < this.size){
            //         target = this.iRefs[index+1];
            //         this.$refs[target][0].focus();
            //     }
            //     return;
            // }
            // backspace/delete
            if(['Backspace', 'ArrowLeft'].indexOf(e.key) !== -1){
                e.preventDefault();
                e.stopPropagation();
                if(this.values[index]){
                    this.values.splice(index,1);
                    return;
                }
                if(index > 0){
                    this.values[index] = '';
                    target = this.iRefs[index-1];
                    // console.log(this.$refs[target]);
                    this.$refs[target][0].focus();
                    this.$refs[target][0].$refs.input.select();
                }
            }
        },
        handlePaste(e){
            let idx = 0;
            let clipboardData = e.clipboardData || window.clipboardData;
            clipboardData = clipboardData.getData('Text').replace(/[^\d]/gi, "");
            if(!clipboardData){
                // non-numeric input
                return;
            }
            for(let i in clipboardData){
                let val = clipboardData[i];
                if(i){
                    this.values[idx] = val;
                    ++idx;
                }
            }
            setTimeout(() => {
                let focusIdx = -1;
                for(let i in this.values){
                    if(this.values[i]){
                        ++focusIdx;
                    }
                }
                if(focusIdx < this.size){
                    let next = this.iRefs[focusIdx];
                    this.$refs[next][0].focus();
                }
            }, 20);

        },
        getEntry(){
            return this.values.join('');
        }
    },
    mounted(){
        this.initData();
        document.addEventListener('paste', this.handlePaste)
    },
    destroyed(){
        document.removeEventListener('paste', this.handlePaste);
    },
    watch: {
        values(){
            if(this.values.length == this.size){
                this.$emit('updated', this.getEntry());
            }
        }
    }
}
</script>
